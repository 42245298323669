<template>
  <div>
    <b-card no-body class="mb-5 py-4">
      <b-card-body>
        <b-card-title title-tag="h2" class="text-csub font-weight-bold">
          MYPLR口座を新規に開設する方
        </b-card-title>
        <b-card no-body class="border-0">
          <b-card-body>
            <b-card-text class="text-csub"
              >次の画面から口座開設申請フォームの入力を行います。</b-card-text
            >
            <b-form-row>
              <b-col offset-sm="3" sm="6" offset-md="4" md="4">
                <router-link
                  v-if="$route.name == 'top-ekyc'"
                  to="/account-opening-ekyc/agreement"
                  ><b-button variant="csub" class="w-100">
                    次へ
                  </b-button></router-link
                >
                <router-link v-else to="/account-opening/agreement"
                  ><b-button variant="csub" class="w-100">
                    次へ
                  </b-button></router-link
                >
              </b-col>
            </b-form-row>
          </b-card-body>
        </b-card>
      </b-card-body>
    </b-card>
    <b-card no-body class="py-4">
      <b-card-body>
        <b-card-title title-tag="h2" class="text-csub font-weight-bold">
          MYPLR口座を既に所有している方
        </b-card-title>
        <b-card no-body class="border-0">
          <b-card-body>
            <b-card-text class="text-csub"
              >MYPLR口座ポータルからログインしてください。</b-card-text
            >
            <b-link
              href="https://plr-plus.cds.or.jp/ui"
              >https://plr-plus.cds.or.jp/ui</b-link
            >
          </b-card-body>
        </b-card>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  created() {
    // storeに連携情報を保存
    this.$store.dispatch('setLinkageInfo', {
      wfCode: this.$route.query.wf_code || null,
      userId: this.$route.query.userid || null,
    })
  },
}
</script>
